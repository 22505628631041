import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

const initGuideBoardSortable = (ulElements, groupname, boardId) => {
  ulElements.forEach((ul) => {
    Sortable.create(ul, {
        group: groupname, // set both lists to same group
        animation: 300,
        onEnd: saveKanban.bind(null, boardId)
    });
  });
};

const saveKanban = (boardId, event) => {
  console.log("event", event)
  console.log("boardID", boardId)
  if (event.item.classList.contains("guideboard-heading")) {
    console.log("move heading")
    let headingId = event.item.dataset.headingId
    let data = new FormData()
    data.append('position', event.newIndex + 1)
    Rails.ajax({
      url: `/admin/guide_boards/${boardId}/guide_board_headings/${headingId}/move`,
      type: "PATCH",
      data: data
    })
  }
  else if (event.item.classList.contains("guideboard-post")) {
    console.log("move post")
    let postId = event.item.dataset.postId
    let newHeadingId = event.to.dataset.headingId
    console.log()
    let data = new FormData()
    data.append('guide_board_id', boardId)
    data.append('guide_board_heading_id', newHeadingId)
    data.append('position', event.newIndex)
    Rails.ajax({
      url: `/admin/guide_boards/${boardId}/guide_board_posts/${postId}/move`,
      type: "PATCH",
      data: data
    })
  }
}

export { initGuideBoardSortable };