import Tagify from '@yaireo/tagify'

export default () => {
  document.addEventListener('turbolinks:load', () => {
    const tagInput = document.getElementById('announcement_tag_list')
    if (tagInput === null) return false;
    const allTags = document.querySelector('[data-tags-collection]').dataset.tagsCollection;

    const tagify = new Tagify(tagInput, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      whitelist: JSON.parse(allTags),
      userInput: false,
      dropdown: {
        classname: 'custom-tagify-look',
        enabled: 0,
        maxItems: 5,
      }
    })
    let controller;

    tagify.on('input', onInput)

    function onInput(e) {
      const value = e.detail.value

      tagify.whitelist = null

      controller && controller.abort()
      controller = new AbortController()

      tagify.loading(true).dropdown.hide()

      fetch(`${location.protocol}//${location.host}/admin/tags?name=${value}`, { signal: controller.signal })
        .then(RES => RES.json())
        .then(function (newWhitelist) {
          tagify.whitelist = newWhitelist.data
          console.log(newWhitelist.data)
          tagify.loading(false).dropdown.show(value)
        })
    }
  });
}