// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import 'jquery'
import('src/plugins')
import handleTag from 'src/handleTag'
import { initGuideBoardSortable } from '../plugins/initSortable';
import Turbolinks from "turbolinks"

import 'css/application'

Rails.start()
Turbolinks.start()

require("@rails/activestorage").start()
handleTag();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", function() {
  const selectAllComplexes =  document.querySelector('#select_all_complexes')
  if (selectAllComplexes) {
    selectAllComplexes.addEventListener('change', function(){
      var checked = this.checked;
      document.querySelectorAll('[data-behaviour="select-all"]').forEach((building) => {
        building.checked = checked
      });
    })
  }

  // deprecated since Heka removes "area"
  // const selectAllSouthComplexes =  document.querySelector('#select_area_south')
  // if (selectAllSouthComplexes) {
  //   selectAllSouthComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-south"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  // const selectAllSouthEastComplexes =  document.querySelector('#select_area_south_east')
  // if (selectAllSouthEastComplexes) {
  //   selectAllSouthEastComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-south_east"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  // const selectAllEastComplexes =  document.querySelector('#select_area_east')
  // if (selectAllEastComplexes) {
  //   selectAllEastComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-east"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  // const selectAllNorthEastComplexes =  document.querySelector('#select_area_north_east')
  // if (selectAllNorthEastComplexes) {
  //   selectAllNorthEastComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-north_east"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  // const selectAllWestComplexes =  document.querySelector('#select_area_west')
  // if (selectAllWestComplexes) {
  //   selectAllWestComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-west"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  // const selectAllOtherComplexes =  document.querySelector('#select_area_other')
  // if (selectAllOtherComplexes) {
  //   selectAllOtherComplexes.addEventListener('change', function(){
  //     var checked = this.checked;
  //     document.querySelectorAll('[data-area="select-area-other"]').forEach((building) => {
  //       building.checked = checked
  //     });
  //   })
  // }

  const guide_heading_is_link =  document.querySelector('#guide_heading_is_link')
  const guide_heading_link =  document.querySelector('#guide_heading_link')
  if (guide_heading_is_link) {
    guide_heading_is_link.addEventListener('change', function(){
      if (guide_heading_is_link.checked === true) {
        guide_heading_link.style.display = "block";
      } else {
        guide_heading_link.style.display = "none";
      }
    })
  }

  const recurring_event =  document.querySelector('#event-recurring')
  const days_of_the_week =  document.querySelector('#event-recurring-fields')
  if (recurring_event) {
    recurring_event.addEventListener('change', function(){
      if (recurring_event.checked === true) {
        days_of_the_week.style.display = "block";
      } else {
        days_of_the_week.style.display = "none";
      }
    })
  }

  const guideboard = document.querySelectorAll(".guideboard");
  const GuideHeadingUls = document.querySelectorAll(".guideboard-heading");
  if (guideboard.length !== 0) {
    initGuideBoardSortable(guideboard, "guideboard", guideboard[0].dataset.id);
  }
  if (GuideHeadingUls.length !== 0) {
    initGuideBoardSortable(GuideHeadingUls, "guideboard-heading", guideboard[0].dataset.id);
  }
});
